import { imageAdapter } from '@/adapters/constructor/utils/image';

const factAdapter = (data) => {
  return {
    id: data.id,
    text: data.text,
    title: data.title,
  };
};

export default ({ data }) => {
  return {
    title: data.title,
    text1: data.text1,
    text2: data.text2,
    text3: data.text3,
    image: imageAdapter(data.banner, data.banner_mobile),

    facts: data.facts?.map(factAdapter),
    factsTitle: data.facts_title,
  };
};
